<template>
	<footer class="text-center text-lg-start bg-body-tertiary text-muted mt-3">
		<!-- Section: Social media -->
		<section class="d-flex p-2 border-bottom media">
			<div class="me-5 d-none d-sm-block">
				<span>Следуйте за нами в социальных сетях:</span>
			</div>
			<div class="me-5 d-sm-none">
				<span>В сетях:</span>
			</div>
			<div class="ms-auto">
				<a href="#">
					<img width="25" src="../assets/telegram-icon.png" alt="Telegram"/>
				</a>
				<a href="#" class="mx-2">
					<img width="26" src="../assets/whatsaap-icon.png" alt="Whatsapp"/>
				</a>
				<a href="#">
					<img width="25" src="../assets/instagramm-icon.png" alt="Instagram"/>
				</a>
			</div>
		</section>
		<!-- Section: Social media -->

		<!-- Section: Links  -->
		<section>
			<div class="text-start">
				<div class="row mt-3">
					<div class="col-12 col-sm-8 col-md-6 col-lg-5 ps-2">
						<div>
							<span class="fw-bold mb-0 text-black links">
								Телефон доставки:
							</span>
							<a href="tel:+79610438408" class="text-reset links">
                                +7-961-043-84-08
							</a>
						</div>
						<div>
							<span class="fw-bold text-black links">
                                О нас
                            </span>
						</div>
						<div>
							<span class="fw-bold text-black links">
                                Помощь
                            </span>
						</div>
					</div>
					<div class="col-12 col-sm-4 col-md-2 col-lg-3 mt-2 ps-2">
						<div>
							<span class="fw-bold links text-black">
                                Меню
                            </span>
						</div>
						<div>
							<span class="fw-bold links text-black">
                                Корзина
                            </span>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-4 col-lg-4 mt-2 text-black  ps-2">
						<div>
                            <span class="fw-bold mb-0 text-black links">
                                Chayxana41
                            </span>
						</div>
						<p>
							Here you can use rows and columns to organize your footer content. Lorem ipsum
							dolor sit amet
						</p>
					</div>
				</div>
			</div>
		</section>
		<div class="text-center p-2 media">
			© 2024-02-21 :
			<a class="text-reset fw-bold" href="https://mdbootstrap.com/">Chayxana41.ru</a>
		</div>
	</footer>
</template>
<script>
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Footer"
}
</script>

<style scoped>
.media {
	background-color: #FE5F00;
	color: white;
}

.links {
	color: #FE5F00;
}

.links:hover {
	color: #FE5F00 !important;
}
</style>