import getRequests from "@/plugins/requests/getRequests";

export default {
    actions: {
        fetchSlaydShow(context) {
            return getRequests('/slayd_shows', 'updateSlaydShow', context)
        }
    },

    mutations: {
        updateSlaydShow(state, slaydShow) {
            state.slaydShow = slaydShow
        }
    },

    state: {
        slaydShow: {
            models: [],
            totalItems:0
        }
    },

    getters: {
        getSlaydShow(state) {
            return state.slaydShow.models
        }
    }
}
