import {createRouter, createWebHistory} from "vue-router/dist/vue-router"

const routes = [
    {
        path: '/',
        component: () => import('@/components/pages/MainPage.vue'),
    },
    {
        path: '/all_product/:menuId',
        component: () => import('@/components/product/AllProducts.vue'),
    },
]

export default createRouter({
    history: createWebHistory(),
    scrollBehavior() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    },
    routes
})
