<template>
    <Header/>
	<router-view/>
	<Footer/>
	<FooterNavbar/>
</template>

<script>
import Header from './components/Header.vue'
import FooterNavbar from "@/components/FooterNavbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'App',
  components: {
	Footer,
    FooterNavbar,
    Header
  }
}
</script>

<style>
body {
    font-family: "Lucida Console", "Courier New", monospace;
}
.row {
	margin: 0;
}
</style>
