<template>
	<header>
		<nav class="navbar navbar-expand-lg">
			<div class="container-fluid ps-2">
				<router-link class="navbar-brand text-light" to="/">Chayxana41</router-link>
				<button @click="isCloseNavbar = !isCloseNavbar"
                        class="navbar-toggler bg-light" type="button" data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
						aria-expanded="false" aria-label="Toggle navigation">
					<span v-if="!isCloseNavbar" class="navbar-toggler-icon"></span>
                    <img v-if="isCloseNavbar" width="30" src="../assets/close-icon.svg" alt="Whatsapp"/>
				</button>
				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<div class="mx-auto"></div>
					<ul class="navbar-nav">
						<li class="nav-item">
							<router-link class="nav-link text-white" to="#">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16">
									<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
								</svg>
								Ежедневно с 09:00 до 24:00
							</router-link>
						</li>
						<li class="nav-item">
							<a class="nav-link text-white" href="tel:+79610438408">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-inbound-fill" viewBox="0 0 16 16">
									<path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zM15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0"/>
								</svg>
								+7-961-043-84-08
                            </a>
						</li>
						<li class="nav-item">
							<div class="d-flex nav-link" role="search">
								<div class="input-group">
									<input type="text" style="width: 135px" class="form-control" placeholder="Поиск"
									>
									<button class="btn bg-secondary-subtle" type="button" id="button-addon2">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
											<path
												d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
										</svg>
									</button>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</nav>
		<div style="background-color: #FE5F00;" class="mb-3">
			<h1 class="text-white text-center fs-4">Доставка еды П-Камчатский</h1>
		</div>
	</header>
</template>

<script>
export default {
    // eslint-disable-next-line
    name: "Header",
    data() {
        return {
            isCloseNavbar: false
        }
    }
}
</script>

<style scoped>
header {
    background-color: #FE5F00;
    padding-bottom: 1px;

}

.navbar-brand {
  font-weight: bold;
}
</style>