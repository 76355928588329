import postRequest from "@/plugins/requests/postRequest";
export default {
    actions: {
        sendOrder(context, data) {
            return postRequest('/orders', data, 'updateOrders', context)
        }
    },

    mutations: {
        updateOrders() {}
    },
}
