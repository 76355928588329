<template>
	<div class="row px-2 d-none d-md-block" id="cart">
		<h5 class="fw-bold pt-2 px-0 mt-4 d-none d-md-block border-bottom border-2 border-black">КОРЗИНА</h5>
		<div class="col-12 col-md-6 col-xl-6 col-xxl-5 mt-1 pe-md-3 justify-content-evenly px-0 mb-2 d-none d-md-block"
			v-for="(product, index) of cartProducts" :key="index">
			<div class="cart-product-item">
				<div class="d-flex position-relative">
					<div class="img-container">
						<img class="product-img" :src="url + product.image.filePath" alt="img"/>
					</div>
					<div>
						<div class="position-relation pb-4">
							<span class="fw-bold position-absolute fs-5"> {{ product.name }} </span>
						</div>
						<div class="price__currency">
							<span class="price-number fs-5">{{ product.price }}</span>
							<img  width="14" class="ms-1 mb-1	" src="../assets/pngwing.com.png" alt="P">
						</div>
						<div class="product-weight">{{ product.weight }} г</div>
					</div>
					<div class="item-control-cart d-inline">
						<div class="items">
							<div class="items__control" @click="decrementProductCount(index)">-</div>
							<div class="items__current">{{ product.count }}</div>
							<div class="items__control" @click="incrementProductCount(index)">+</div>
						</div>
					</div>
					<div class="item-delete-cart d-inline" @click="deleteProduct(index)">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
							<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
						</svg>
					</div>
				</div>
			</div>
		</div>
		<OrderSend/>
	</div>
	<div class="row px-2 mt-3 d-md-none" v-if="isActiveMobilCart">
		<h5 class="px-0 fw-bold mb-2 border-bottom border-2 border-black">
			КОРЗИНА {{ cartProducts.length ? '' : 'ПУСТА' }}
		</h5>
		<div class="col-12 col-md-6 col-xl-6 col-xxl-5 mt-1 pe-md-3 justify-content-evenly px-0 mb-2"
			v-for="(product, index) of cartProducts" :key="index">
			<div class="cart-product-item">
				<div class="d-flex position-relative">
					<div class="img-container">
						<img class="product-img" :src="url + product.image.filePath" alt="img"/>
					</div>
					<div>
						<span class="fw-bold"> {{ product.name }} </span>
						<div class="price__currency pt-2">
							<span class="price-number fs-6">{{ product.price }}</span>
							<img  width="10" class="ms-1 mb-1	" src="../assets/pngwing.com.png" alt="P">
						</div>
						<div class="product-weight">{{ product.weight }} г</div>
					</div>
					<div class="item-control-cart d-inline">
						<div class="items">
							<div class="items__control" @click="decrementProductCount(index)">-</div>
							<div class="items__current">{{ product.count }}</div>
							<div class="items__control" @click="incrementProductCount(index)">+</div>
						</div>
					</div>
					<div class="item-delete-cart d-inline" @click="deleteProduct(index)">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
							<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
						</svg>
					</div>
				</div>
			</div>
		</div>
		<OrderSend v-if="cartProducts.length"/>
	</div>
    <div @click="refreshWindow()" class="modal fade" id="exampleModal" tabindex="1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content fw-bold pb-2 px-3">
                <div class="modal-header ps-0">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Chayxana41</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="icon-container position-relation">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-check text-center" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                    </svg>
                </div>
                Ваш заказ отправлен, мы свяжемся с вами в ближайшее время...
            </div>
        </div>
    </div>
</template>

<script>
import OrderSend from "@/components/OrderSend.vue";
import {mapState} from "vuex";

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "cart",
	components: {OrderSend},
	props: {
		isActiveMobilCart: {
			type: Boolean,
		},
	},
	data() {
		return {
			url: process.env.VUE_APP_API_URL + '/media/'
		}
	},
	computed: {
		...mapState(['cartProducts'])
	},
	methods: {
		incrementProductCount(productIndex) {
			if (this.cartProducts[productIndex].count >= 1) {
				++this.cartProducts[productIndex].count
			}
		},
		decrementProductCount(productIndex) {
			if (this.cartProducts[productIndex].count === 1) {
				this.deleteProduct(productIndex)
			}

			if (this.cartProducts.length && this.cartProducts[productIndex].count > 1) {
				--this.cartProducts[productIndex].count
			}
		},
		deleteProduct(productIndex) {
			this.cartProducts.splice(productIndex, 1)
		},
        refreshWindow() {
            location.reload()
        }
	}
}
</script>

<style scoped>
.product-img {
	width: 100%;
	border-radius:5px 0 0 5px;
	border-right: white solid 3px;
}

.img-container {
	width: 143px;
	height: 80px;

}

.cart-product-item {
	border: #FE5F00 solid 1px;
	background: white;
	height: 80px;
	border-radius: 5px;
}

.cart-product-item:hover {
	box-shadow: 2px 2px 4px #FE5F00;
}

.product-weight {
	color: #6c757d !important;
	font-size: 13px;
}

.price-number {
	color: #218c21;
}

.items {
	background: #f2ede7;
	font-family: "Merriweather", serif;
	border-radius: 8px;
	width: 90px;
	display: flex;
	font-size: 18px;
	height: 30px;
	overflow: hidden;
}

.items__control {
	width: 40px;
	cursor: pointer;
	transition: 0.2s ease-in;
	text-align: center;
}

.items__control:hover {
	background: #eb5a1e;
	color: #fff;
}


.items__current {
	text-align: center;
	width: 40px;
}

.item-control-cart {
	position: absolute;
	bottom: 5px;
	right: 3px;
}

.item-delete-cart {
	position: absolute;
	top: 0px;
	right: 2px;
	color: #5c5b5b;
}

.item-delete-cart:hover {
	color: #f51616;
}


.icon-container {
    height: 135px;
}

.bi-check {
    color: #86CC46;
    width: 180px;
    height: 180px;
    position: absolute;
    z-index: 2;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>