import getRequests from "@/plugins/requests/getRequests";

export default {
    actions: {
        fetchMenus(context) {
            return getRequests('/menus', 'updateMenus', context)
        }
    },

    mutations: {
        updateMenus(state, menus) {
            state.menus = menus
        }
    },

    state: {
        menus: {
            models: [],
            totalItems:0
        }
    },

    getters: {
        getMenus(state) {
            return state.menus.models
        }
    }
}
