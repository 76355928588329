import {createStore} from "vuex"
import menu from "@/plugins/vuex/modules/menu";
import product from "@/plugins/vuex/modules/product";
import slaydShow from "@/plugins/vuex/modules/slaydShow";
import cartProducts from "@/plugins/vuex/modules/cartProducts";
import orderSend from "@/plugins/vuex/modules/order";
export default createStore({
    modules: {
        menu,
        product,
        slaydShow,
        cartProducts,
        orderSend
    }
})