import getRequests from "@/plugins/requests/getRequests";

export default {
    actions: {
        fetchProducts(context, menuId) {
            return getRequests('/products?menu=' + menuId, 'updateProducts', context)
        }
    },

    mutations: {
        updateProducts(state, products) {
            state.products = products
        }
    },

    state: {
        products: {
            models: [],
            totalItems:0
        }
    },

    getters: {
        getProducts(state) {
            return state.products.models
        }
    }
}
