export default {
    state: () => [],
    mutations: {
        addProductToCart(state, newProduct) {
            const existingProduct = state.find(product => product.id === newProduct.id);
            if (existingProduct) {
                existingProduct.count += newProduct.count;
            } else {
                state.push(newProduct);
            }
        }
    }
};