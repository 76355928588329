<template>
    <div v-if="cartProducts.length" class="absolut-cart d-none d-md-block">
        <a href="#cart">
            <div class="text-center">
                <div class="text-white absolut-cart-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-basket2" viewBox="0 0 16 16">
                        <path d="M4 10a1 1 0 0 1 2 0v2a1 1 0 0 1-2 0zm3 0a1 1 0 0 1 2 0v2a1 1 0 0 1-2 0zm3 0a1 1 0 1 1 2 0v2a1 1 0 0 1-2 0z"/>
                        <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-.623l-1.844 6.456a.75.75 0 0 1-.722.544H3.69a.75.75 0 0 1-.722-.544L1.123 8H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 6h1.717L5.07 1.243a.5.5 0 0 1 .686-.172zM2.163 8l1.714 6h8.246l1.714-6z"/>
                    </svg>
                    <div class="products-price">
                        {{ totalPrice.toLocaleString('de-DE')}}
                        <img height="12" class="mb-1" src="../assets/rouble-icon.svg" alt="P">
                    </div>
                </div>
            </div>
        </a>
    </div>
	<div class="row navbar-footer d-md-none border-top">
		<div role="button"
			class="col border-0 border-end border-dark"
            :class="cartProducts.length ? 'navbar-cart' : ''"
			data-bs-toggle="offcanvas"
			data-bs-target="#offcanvasBottom"
			aria-controls="offcanvasBottom"
			@click="links.menu = false; links.cart = true">
			<div class="text-center">
				<div class="text-white">
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-basket2" viewBox="0 0 16 16">
						<path d="M4 10a1 1 0 0 1 2 0v2a1 1 0 0 1-2 0zm3 0a1 1 0 0 1 2 0v2a1 1 0 0 1-2 0zm3 0a1 1 0 1 1 2 0v2a1 1 0 0 1-2 0z"/>
						<path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-.623l-1.844 6.456a.75.75 0 0 1-.722.544H3.69a.75.75 0 0 1-.722-.544L1.123 8H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 6h1.717L5.07 1.243a.5.5 0 0 1 .686-.172zM2.163 8l1.714 6h8.246l1.714-6z"/>
					</svg>
					<div class="products-price">
						{{ totalPrice.toLocaleString('de-DE') }}
						<img height="9.8"  src="../assets/rouble-icon.svg" alt="P">
					</div>
				</div>
			</div>
		</div>
        <div role="button"
			class="col border-0 border-end border-start border-dark"
			data-bs-toggle="offcanvas"
			data-bs-target="#offcanvasBottom"
			aria-controls="offcanvasBottom"
			@click="links.cart = false; links.menu = true">
			<div class="text-center text-white">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-menu-down" viewBox="0 0 16 16">
					<path d="M7.646.146a.5.5 0 0 1 .708 0L10.207 2H14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3.793zM1 7v3h14V7zm14-1V4a1 1 0 0 0-1-1h-3.793a1 1 0 0 1-.707-.293L8 1.207l-1.5 1.5A1 1 0 0 1 5.793 3H2a1 1 0 0 0-1 1v2zm0 5H1v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM2 4.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m0 4a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5"/>
				</svg>
				<div class="products-price">
					МЕНЮ
				</div>
			</div>
		</div>
		<div role="button" class="col border-0 border-end border-start border-dark">
			<div class="text-center text-white">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard-fill" viewBox="0 0 16 16">
					<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5M9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8m1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5m-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96q.04-.245.04-.5M7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0"/>
				</svg>
				<div class="products-price">
					О НАС
				</div>
			</div>
		</div>
		<div role="button" class="col border-0 border-start border-secondary">
			<div class="text-center text-white">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-octagon-fill" viewBox="0 0 16 16">
					<path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353zM5.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927"/>
				</svg>
				<div class="products-price">
					Помощь
				</div>
			</div>
		</div>
	</div>

	<div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header">
			<button type="button" class="btn-close ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body p-0">
			<Cart
				v-if="links.cart"
				:is-active-mobil-cart="true"
			/>
			<Menus
				v-if="links.menu"
				:is-active-mobil-menu="true"
			/>
		</div>
	</div>
</template>

<script>
import Menus from "@/components/Menus.vue";
import Cart from "@/components/Cart.vue";
import {mapMutations, mapState} from "vuex";
export default {
	data() {
		return {
			links: {
				cart: false,
				menu: false,
			},
            totalPrice: 0
		}
	},
	name: "NavbarFooter",
	components: {Menus, Cart},
    computed: {
        ...mapState(['cartProducts'])
    },
    methods: {
      ...mapMutations(['totalPriceProducts'])
    },
    watch: {
        cartProducts: {
            handler: function () {
                let newTotalPrice = 0;
                this.cartProducts.forEach((product) => {
                    newTotalPrice += product.price * product.count;
                });

                this.totalPrice = newTotalPrice;
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style scoped>
.navbar-footer {
	background-color: #ff6e1a;
	height: 45px;
	position: sticky;
	bottom: 0;
	z-index: 1;
}

.navbar-cart {
    background: #86CC46;
}

.products-price {
	font-size: 13px;
}
.offcanvas.offcanvas-bottom {
	height: 80%;
}

.absolut-cart {
    height: 87px;
    width: 87px;
    border-radius: 50%;
    border: solid #86CC46;
    background: #86CC46;
    position: sticky;
    bottom: 17%;
    left: 90%;
}

.absolut-cart a {
    text-decoration: none;
}

.absolut-cart-text {
    margin-top: 10px;
}

.absolut-cart-text .products-price {
    font-size: 17px;
}
</style>